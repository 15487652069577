import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.alerts.length}}\n  {{#each this.alerts as |alert|}}\n    <UiInlineNotification\n      @class=\"mb2\"\n      @icon=\"warning\"\n      @buttonIcon={{if (eq alert.category \"subscriber-upload\") \"ascending-arrow\" \"\"}}\n      @isError={{eq alert.severity \"high\"}}\n      @isWarning={{eq alert.severity \"medium\"}}\n      @title={{t\n        (concat \"components.anlNotifications.alertTypes.\" alert.category)\n        date=(format-date alert.createdAt format=\"ddmmyyyy\")\n        time=(format-time alert.createdAt format=\"hhmm\")\n        warning=(eq alert.severity \"medium\")\n      }}\n      @buttonText={{if\n        (eq alert.category \"subscriber-upload\")\n        (t \"components.anlNotifications.alertButtonText.subscriber-upload\")\n        \"\"\n      }}\n      @onButtonClick={{fn this.openAlert alert}}\n    />\n  {{/each}}\n{{/if}}", {"contents":"{{#if this.alerts.length}}\n  {{#each this.alerts as |alert|}}\n    <UiInlineNotification\n      @class=\"mb2\"\n      @icon=\"warning\"\n      @buttonIcon={{if (eq alert.category \"subscriber-upload\") \"ascending-arrow\" \"\"}}\n      @isError={{eq alert.severity \"high\"}}\n      @isWarning={{eq alert.severity \"medium\"}}\n      @title={{t\n        (concat \"components.anlNotifications.alertTypes.\" alert.category)\n        date=(format-date alert.createdAt format=\"ddmmyyyy\")\n        time=(format-time alert.createdAt format=\"hhmm\")\n        warning=(eq alert.severity \"medium\")\n      }}\n      @buttonText={{if\n        (eq alert.category \"subscriber-upload\")\n        (t \"components.anlNotifications.alertButtonText.subscriber-upload\")\n        \"\"\n      }}\n      @onButtonClick={{fn this.openAlert alert}}\n    />\n  {{/each}}\n{{/if}}","moduleName":"additive-newsletter/components/anl-notifications.hbs","parseOptions":{"srcName":"additive-newsletter/components/anl-notifications.hbs"}});
import ENV from 'additive-newsletter/config/environment';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { nextTick } from '@additive-apps/ui/utils/dom-util';

export default class AcrmNotifications extends Component {
  @service currentUser;
  @service uiToast;
  @service intl;
  @service authenticatedFetch;
  @service uiState;
  @service router;

  @tracked
  alerts = [];

  constructor() {
    super(...arguments);

    this.fetchAlerts.perform();
  }

  @task(function* () {
    try {
      const baseUrl = `${ENV.APP.apiBaseHost}/${this.currentUser.currentOrganization.id}`;
      const response = yield this.authenticatedFetch.fetch(`${baseUrl}/alerts`);

      if (!response?.ok) throw response;

      const json = yield response.json();

      this.alerts = json?.alerts;
    } catch (error) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  })
  fetchAlerts;

  @action
  async openAlert(alert) {
    if (alert.category === 'subscriber-upload') {
      await this.router.transitionTo(
        'instance.address-books.address-book.subscribers',
        alert.resource.id
      );

      await nextTick();

      this.uiState.getState('address-book-navdrawer-subscribers').toggle();
    }
  }
}
